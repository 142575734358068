import React from "react";
import PropTypes from "prop-types";
import Page from "../components/Page";
import staticData from "../data/index";
import processData from "../data/processData";
import { graphql } from "gatsby";

const DepartmentTemplate = ({ pageContext, data }) => {
  data.cms.department.image.url = data.cms.department.image.url.replace('.s3.eu-west-1.', '.s3-eu-west-1.');
  let pageData = processData(
    staticData[pageContext.name],
    data,
    pageContext.name
  );
  return <Page name={pageContext.name} data={pageData} />;
};

DepartmentTemplate.propTypes = {
  pageContext: PropTypes.shape(),
  data: PropTypes.shape(),
};

export const query = graphql`
  query($id: ID!) {
    cms {
      department(id: $id) {
        id
        name
        description
        active
        created_at
        updated_at
        slug
        image {
          url
        }
        jobs {
          id
          title
          active
          slug
        }
        quote {
          Author
          AuthorRole
          quote
          AuthorPicture {
            url
          }
        }
      }
    }
  }
`;

export default DepartmentTemplate;
